.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8%;
    background-color:#112B3A;
    color: white;
    text-align: center;
    position: fixed;
    text-align: end;
    padding-right: 20px;
    
  }
  .c-icon {
    display: inline-block;
    color: inherit;
    display: none !important;
    text-align: center;
    fill: currentColor;
}


  .footer-all-app{
    color: #ffffff;
  }
  .footer-labe-obaps{
    color: #ffffff;
    
  }.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#112B3A;
  color: white;
  text-align: center;
  position: fixed;
  text-align: end;
  padding-right: 20px;
}
.footer-all-app{
  color: #ffffff;
}
.footer-labe-obaps{
  color: #ffffff;
}.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#112B3A;
  color: white;
  text-align: center;
  position: fixed;
  text-align: end;
  padding-right: 20px;
}
.footer-all-app{
  color: #ffffff;
}
.footer-labe-obaps{
  color: #ffffff;
}.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#112B3A;
  color: white;
  text-align: center;
  position: fixed;
  text-align: end;
  padding-right: 20px;
}
.footer-all-app{
  color: #ffffff;
}
.footer-labe-obaps{
  color: #ffffff;
}
::placeholder{
    color: #bbb9b9!important;
    font-size: 12px!important;
    font-weight: 500!important;
    font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue","Fira Sans",Ubuntu,Oxygen,"Oxygen Sans",Cantarell,"Droid Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Emoji","Segoe UI Symbol","Lucida Grande",Helvetica,Arial,sans-serif !important;
  }
  /* body{
    font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue","Fira Sans",Ubuntu,Oxygen,"Oxygen Sans",Cantarell,"Droid Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Emoji","Segoe UI Symbol","Lucida Grande",Helvetica,Arial,sans-serif;
  } */
  /* body{
    font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue","Fira Sans",Ubuntu,Oxygen,"Oxygen Sans",Cantarell,"Droid Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Emoji","Segoe UI Symbol","Lucida Grande",Helvetica,Arial,sans-serif;
  } */
  body{
    font-family: loto!important;
    background-color: #ffffff!important;
  }
  input:focus, textarea:focus, select:focus{
    outline:none;
    border: 1px solid #1de8b4;
    box-shadow: 0px 0px 2px #1de8b4;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  button:focus {outline:0;}
  .react-datepicker-wrapper {
    display: block!important;
    padding: 0;
    border: 0;
  }
  a:hover{
    color: #1de8b4;
    text-decoration: none;
  }
  a:active{
    text-decoration: none;
  }
  a:visited{
    text-decoration: none;
  }
  .foo-bar{
    border-top-left-radius:10px  !important;
    border-bottom-right-radius: 10px !important;
    font-size: 14px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
   background-color: rgb(95, 246, 201) !important;
   color: rgb(75, 72, 72);
  }
  .foo-bar-error{
    border-top-left-radius:10px  !important;
    border-bottom-right-radius: 10px !important;
    font-size: 14px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
   background-color: salmon !important;
   color: rgb(255, 255, 255);
  }
  .c-body{
      background-color: white !important;

  }
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 90% !important;
    padding-bottom: 6px !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}